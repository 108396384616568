<template>
  <a-card :class="'column-card'" :bodyStyle="cardBodyStyle" @click="onHandleModalOpen">
    <div class="card-flex-row">
      <div class="card-id">#{{ orderData.id }}</div>
      <div class="card-date">{{
          toLocalDate
        }}
      </div>
    </div>
    <template v-if="orderData.positions.length">
      <div
        class="card-data-item"
        v-for="(position, index) in orderData.positions"
        :key="index">
        {{ position.name }} : {{ position.quantity }}
      </div>
    </template>
    <div
      class="card-data-item"
      v-if="orderData.comment"
    ><span style="color: rgb(146, 152, 155)">Комментарий:</span> {{ orderData.comment }}
    </div>
    <div class="card-flex-row">
      <div class="card-empty"></div>
      <div class="card-sum">{{ orderData.total.discountTotalPrice }} ₽</div>
    </div>
  </a-card>
</template>

<script>
//import moment from "vue-moment";

export default {
  name: 'order-desk-column-card',
  props: {
    orderData: Object
  },
  computed: {
    toLocalDate() {
      const date = new Date(this.orderData.created_at);
      const month = date.getMonth();
      return date.getDate() + '.' + (month < 10 ? '0' + month : month) + '.' + date.getFullYear();
    }
  },
  data() {
    return {
      cardBodyStyle: {
        padding: '7px',
      }
    }
  },
  mounted() {
  },
  methods: {
    onHandleModalOpen() {
      this.$emit('open-modal');
    }
  }
}
</script>

<style>
.column-card {
  line-height: 15px;
  padding: 7px;
  text-align: left;
  font-size: 12px;
  cursor: pointer;
}

.card-flex-row {
  display: flex;
}

.card-flex-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-data-item {

}

.card-date, .card-sum {
  color: rgb(146, 152, 155);
}
</style>
