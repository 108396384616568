<template>
  <Auth v-if="!$auth.check()"></Auth>
  <Desk v-else></Desk>
</template>

<script>

import Auth from "./components/auth/Auth";
import Desk from "./components/Desk";

export default {
  name: 'App',
  components: {
    Auth,
    Desk
  },
  beforeMount() {
    if (!this.$auth.check()) {
      this.$router.push('/auth');
    }
  },
  data() {
    return {};
  }
}
</script>

<style>
  html, body, #app {
    height: 100%;
    margin: 0;
  }
</style>
