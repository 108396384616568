<template>
  <a-flex :justify="'center'" :align="'center'" :vertical="true" :class="'auth_modal'">
    <a-card style="width: 500px">
      <a-form
        :model="formInputs"
        name="auth"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
        autocomplete="on"
        @finish="onFinish"
        @finishFailed="onFinishFailed"
        @submit="onSubmit"
      >
        <a-form-item
          label="Логин"
          labelAlign="left"
          name="login"
          :rules="[{ required: true, message: 'Обязательное поле' }]"
        >
          <a-input v-model:value="formInputs.login"/>
        </a-form-item>

        <a-form-item
          label="Пароль"
          labelAlign="left"
          name="password"
          :rules="[{ required: true, message: 'Обязательное поле' }]"
        >
          <a-input-password v-model:value="formInputs.password"/>
        </a-form-item>

        <a-form-item name="remember" :wrapper-col="{ offset: 0, span: 0 }" :class="'mb12'">
          <a-checkbox v-model:checked="formInputs.remember">Запомнить меня</a-checkbox>
        </a-form-item>

        <a-form-item :wrapper-col="{ offset: 18, span: 6 }" :class="'mb12'">
          <a-button type="primary" html-type="submit">Отправить</a-button>
        </a-form-item>
      </a-form>
    </a-card>
  </a-flex>
</template>

<script>
import {message} from "ant-design-vue";

export default {
  name: 'auth-template',
  props: {},
  data() {
    return {
      'formInputs': {
        'login': '',
        'password': '',
        'remember': false,
      },
      notificationKey: 'updatable',
    }
  },
  beforeMount() {
    if (this.$auth.check()) {
      this.$router.push('/order-desk');
    }
  },
  methods: {
    async onFinish(formData) {
      const response = await this.$http.postForm('/api/', {
        'method': 'auth',
        'action': 'login',
        'data': {
          'login': formData.login,
          'password': formData.password,
          'remember': formData.remember
        }
      });

      if(response.data.status) {
        message.success({ content: response.data.response, duration: 2, 'key': this.notificationKey });
        this.$auth.token(null, response.data.data.token, false);
        this.$auth.user(response.data.data);

        if(formData.remember) {
          this.$auth.remember(JSON.stringify(response.data.data));
        }

        this.$router.push('/order-desk');
      } else {
        message.error({content: response.data.response, duration: 5, 'key': this.notificationKey});
      }
    },
    onFinishFailed(/*values, errorFields*/) {
    },
    onSubmit(/*event*/) {
      message.loading({ content: 'Загрузка...', 'key': this.notificationKey});
    },
  }
}
</script>

<style>
.auth_modal {
  height: 100%;
}
.mb12 {
  margin-bottom: 12px;
}
</style>
