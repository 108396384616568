import * as Vue from 'vue'
import Antd from 'ant-design-vue';

import App from './App.vue'

import http from './http';
import store from './store';
import router from "./router";
import auth from './plugins/auth';

const app = Vue.createApp(App);
app.use(http);
app.use(store);
app.use(router);
app.router = router;

app.use(auth);
app.use(Antd);

app.config.errorHandler = (err) => {
    console.log(err);
}

app.mount('#app');



