<template>
  <a-layout :class="'layout-custom'">
    <a-layout-sider :style="siderStyle" :width="siderWidth" @mouseover="onSiderMouseOverHandle"
                    @mouseleave="onSiderMouseLeaveHandle">
      <a-menu v-model:selectedKeys="selectedKeys" theme="light" mode="inline" :class="'menu-custom'">
        <a-menu-item key="1">
          <pie-chart-outlined/>
          <span>Заказы</span>
        </a-menu-item>
      </a-menu>
      <a-button danger :size="'middle'" :class="'logout-btn'" @click="onLogoutHandle">
        Выйти
      </a-button>
    </a-layout-sider>
    <a-layout>
      <a-layout-content :style="contentStyle">
        <router-view></router-view>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>

<script>
import {PieChartOutlined } from '@ant-design/icons-vue';

export default {
  name: 'desk-item',
  props: {},
  components: {
    PieChartOutlined
  },
  data() {
    return {
      'selectedKeys': ["1"],
      'siderWidth': 80,
      'siderStyle': {
        position: 'relative',
        textAlign: 'center',
        lineHeight: '120px',
        color: '#000',
        backgroundColor: 'transparent',
        transition: '.4s',
        borderRadius: '10px',
        border: '1px solid #d9d9d9',
      },
      contentStyle: {
        textAlign: 'center',
        minHeight: 120,
        lineHeight: '10px',
        color: '#000',
        backgroundColor: 'transparent',
        marginLeft: '10px',
        borderRadius: '10px',
        border: '1px solid #d9d9d9',
        overflow: 'hidden',
        overflowY: 'scroll',
        position: 'relative'
      },
    }
  },
  mounted() {
  },
  methods: {
    onSiderMouseOverHandle() {
      this.siderWidth = 240;
    },
    onSiderMouseLeaveHandle() {
      this.siderWidth = 80;
    },
    onLogoutHandle() {
      this.$auth.unremember();
      this.$auth.token(null, null, false);
      this.$auth.user({});
      location.reload();
    }

  }
}
</script>

<style>
.layout-custom {
  height: 100%;
  padding: 10px;
}
.menu-custom {
  background-color: transparent;
}
.logout-btn {
  position: absolute;
  bottom: 30px;
  width: 92%;
  left: 4%;
}
</style>
