<template>
  <a-col :span="4">
    <div class="column-title">{{ columnData.stage }}</div>
    <div class="column-subtitle">{{ columnData.cnt }} {{ orderCountTranslate }} на сумму {{ columnData.sum }}</div>
    <a-divider :style="dividerStyle" />

    <template v-if="columnData.items.length">
      <OrderDeskColumnCard
        v-for="(orderData, index) in columnData.items"
        :key="index"
        :order-data="orderData"
        @open-modal="() => { return onHandleModalOpen(orderData, columnData.permissions); }">
      </OrderDeskColumnCard>
      <br />
    </template>

  </a-col>
</template>

<script>
import OrderDeskColumnCard from "./OrderDeskColumnCard";

export default {
  name: 'order-desk-column',
  components: {
    OrderDeskColumnCard
  },
  props: {
    columnData: Object,
    color: String,
  },
  computed: {
    orderCountTranslate() {
      let defaultTitle = 'заказ';
      if(this.columnData.cnt > 1 && this.columnData.cnt < 5) {
        defaultTitle = 'заказа';
      } else if(this.columnData.cnt === 0 || this.columnData.cnt >= 5) {
        defaultTitle = 'заказов';
      }

      return defaultTitle;
    }
  },
  data() {
    return {
      cardBodyStyle: {
        padding: '7px',
      },
      dividerStyle: {
        height: '3px',
        backgroundColor: this.color,
        margin: '12px 0',
      }
    }
  },
  mounted() {
  },
  methods: {
    onHandleModalOpen(order, permissions) {
      this.$emit('open-modal', order, permissions);
    }
  }
}
</script>

<style>
.column-title {
  padding: 20px 0 0;
  font-weight: 500;
  color: rgb(52, 71, 103);
  font-size: 15px;
}
.column-subtitle {
  color: rgb(52, 71, 103);
  font-size: 11px;
  margin-top: 7px;
}
</style>
