import * as VueRouter from 'vue-router'
import Auth from "@/components/auth/Auth";
import OrderDesk from "@/components/order/OrderDesk";


const routes = [
    { path: '/order-desk', component: OrderDesk },
    { path: '/auth', component: Auth },
]

const router = VueRouter.createRouter({
    history: VueRouter.createWebHashHistory(),
    routes,
})

export default router;